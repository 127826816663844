import React, { useRef, useState } from 'react';
import uniqid from 'uniqid';

import Popover from 'devextreme-react/popover';

import classes from './ButtonGroupItem.module.scss';

interface IButtonGroupItemProps {
  iconExpr?: string;
  item: any;
  textExpr?: string;
  value?: number | string;
  valueExpr?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ButtonGroupItem: React.FC<IButtonGroupItemProps> = ({
  iconExpr = 'icon',
  item,
  textExpr = 'text',
  value,
  valueExpr,
  onClick,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const activeClass =
    valueExpr && value === item[valueExpr] ? classes.active : '';

  const id = useRef(uniqid());

  const onToggleTooltipHandler = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const onButtonClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (onClick) {
      // console.log('VALUE', e);
      onClick(e);
    }
  };

  return (
    <>
      {item.tooltip && (
        <Popover
          position="top"
          target={`#${id.current}`}
          visible={tooltipVisible}
        >
          {item.tooltip()}
        </Popover>
      )}
      <button
        className={`${classes.btn} ${activeClass}`}
        disabled={item.disabled}
        id={id.current}
        type="button"
        onClick={onButtonClickHandler}
        onMouseEnter={onToggleTooltipHandler}
        onMouseLeave={onToggleTooltipHandler}
      >
        <div className={classes.wrapper}>
          {item[iconExpr] && (
            <img
              alt=""
              className={classes.img}
              src={item[iconExpr]}
              style={{ color: 'red' }}
            />
          )}
          <span className={classes.text}>{item[textExpr]}</span>
        </div>
      </button>
    </>
  );
};

export default ButtonGroupItem;
