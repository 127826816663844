import React, { useEffect, useState } from 'react';

// DX
import Button from 'devextreme-react/button';
import Checkbox from 'devextreme-react/check-box';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';

// Components
import { FormSection } from '../';

import { stateActions, useAppState } from '../../hooks/use-app-state';

// Styles
import classes from './QuoteGeneration.module.scss';

interface IQuoteGenerationProps {
  onNextClick: () => void;
  onPreviousClick: () => void;
}

const QuoteGeneration: React.FC<IQuoteGenerationProps> = ({
  onNextClick,
  onPreviousClick,
}) => {
  const { appDispatch, appState } = useAppState();
  const [optionBtnStyle, setOptionBtnStyle] = useState({
    option1: classes.btn,
    option2: classes.btn,
  });
  const [showPopup, setShowPopup] = useState(false);

  const onNextClickHandler = () => {
    if (!appState.termsAgreed) {
      notify(
        {
          message: 'You must agree to the terms and conditions.',
          position: 'top',
          width: '60%',
        },
        'error',
        2000
      );
      return;
    }

    onNextClick();
  };

  const onOptionClickHandler = (withSiteSurvey: boolean) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field: 'withSiteSurvey', value: withSiteSurvey },
    });
  };

  useEffect(() => {
    if (appState.formData.withSiteSurvey) {
      setOptionBtnStyle({
        option1: classes.btn,
        option2: `${classes.btn} ${classes.btnActive}`,
      });
    } else {
      setOptionBtnStyle({
        option1: `${classes.btn} ${classes.btnActive}`,
        option2: classes.btn,
      });
    }
  }, [appState.formData.withSiteSurvey]);

  const onTermsClickHandler = (e: any) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const onPopupHidingHandler = () => {
    setShowPopup(false);
  };

  const onCheckboxValueChangedHandler = (e: any) => {
    appDispatch({
      type: stateActions.STATE_TERMS_AGREED_CHANGED,
      payload: e.value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FormSection title="Generation of Quote">
        <div className={classes.wrapper}>
          <h3>PRSA Compliant Map Quote</h3>
          <p>Includes:</p>
          <ul>
            <li>Official OSI Map Ordering, Purchasing & Printing</li>
            <li>PI Cover</li>
            <li>Marking of Map by an Engineer / Architect</li>
            <li>Professional Certification of Map</li>
            <li>Issuance of PRA Compliant Map</li>
            <li>
              Postage of Map by Registered Post to Owner/Solicitor as Directed
            </li>
            <li>
              Emailing of Electronic Copy of PRA Compliant Map & OSI (Ordnance
              Survery Ireland Format) Map
            </li>
            <li>Guaranteed 7 Day Turnaround</li>
            <li>Invoice Issued by Email on Purchase</li>
          </ul>
          <p className={classes.optionHeader}>
            Please select one of the following options:
          </p>
          {appState.withMultistorey && (
            <div className={classes.infoBox}>
              <span>
                Please Note: Option 2 only available as a full site survey must
                be carried out for Multistorey PRA Maps.
              </span>
            </div>
          )}
          <div className={classes.optionWrapper}>
            <div className={classes.container}>
              <h5>
                Standard PRA Compliant Map <br /> (without Site Survey)
              </h5>
              <Button
                className={optionBtnStyle.option1}
                disabled={
                  appState.formData.mapType === 'Standard with Multistorey'
                }
                text={`€${appState.totalPrice - appState.propertyAddrPrice}.00`}
                onClick={() => onOptionClickHandler(false)}
              />
              <span className={classes.priceIncVat}>
                Price ex. VAT @ 23% (€
                {(
                  appState.totalPrice -
                  appState.propertyAddrPrice +
                  (appState.totalPrice - appState.propertyAddrPrice) * 0.23
                ).toFixed(2)}
                )
              </span>
              <p>
                This is normally sufficient for maps where the recorded OSI map
                of the property reflects actual boundaries. Our Engineers will
                produce the PRA based on the OSI Map and not survery the plot -
                NOTE: The Land registry is not conclusive on boundaries and the
                onus is on the property owner to have correct boundaries.
              </p>
            </div>
            <div className={classes.container}>
              <h5>
                Standard PRA Compliant Map <br /> with Full Site Survey by
                Engineer/Architect
                {appState.withMultistorey && (
                  <>
                    <br /> Including Multi Storey Floor Plan
                  </>
                )}
              </h5>
              <Button
                className={optionBtnStyle.option2}
                text={`€${appState.totalPriceWithSurvey}.00`}
                onClick={() => onOptionClickHandler(true)}
              />
              <span className={classes.priceIncVat}>
                Price ex. VAT @ 23% (€
                {(
                  appState.totalPriceWithSurvey +
                  appState.totalPriceWithSurvey * 0.23
                ).toFixed(2)}
                )
              </span>
              <p>
                Where there are any doubts or concerns regarding the accuracy of
                the OSI map it is recommended to conduct a full survey of site.
                This ensures accurate mapping and no issues with registration or
                additonal queries raised by the Property Registration Authority.
              </p>
            </div>
          </div>
          <div className={classes.termsWrapper}>
            <Checkbox
              text="I agree to the terms and conditions of Propertymaps.ie"
              value={appState.termsAgreed}
              onValueChanged={onCheckboxValueChangedHandler}
            />
            <button onClick={onTermsClickHandler}>
              Read Terms & Conditions
            </button>
          </div>
        </div>
      </FormSection>
      <div className={classes.btnWrapper}>
        <Button className={classes.btn} onClick={onPreviousClick}>
          <div className={classes.btnContent}>
            <i className="dx-icon dx-icon-chevronleft"></i>
            <span>Instant Quote</span>
          </div>
        </Button>
        <Button
          className={`${classes.btn} ${classes.btnPrimary}`}
          onClick={onNextClickHandler}
        >
          <div className={classes.btnContent}>
            <span>Complete Your Order</span>
            <i className="dx-icon dx-icon-chevronright"></i>
          </div>
        </Button>
      </div>
      <Popup
        closeOnOutsideClick
        height="50%"
        showCloseButton={true}
        title="Terms & Conditions"
        visible={showPopup}
        onHiding={onPopupHidingHandler}
      >
        <ScrollView height="100%" width="100%">
          <p>
            All quotes are based on the information provided by the client as
            being accurate, precise and up to date. Should the actual property
            differ from the information supplied, Properymaps.ie may decline to
            provide the service and refund any fees paid or may adjust the fees
            with regard to the actual property and give the client the option to
            continue with updated fees or to cancel the order with full refund.
          </p>
          <br />
          <p>
            All maps and information provided by the user either Solicitor,
            individual or another must be accurate and clearly set out the
            boundaries to be certified and explain clearly why the boundary is
            where as indicated on the supplied documentation. Propertymaps.ie
            accepts no responsibility for inaccurate information and maps
            supplied that are inaccurate.
          </p>
          <br />
          <p>
            Where the client opts not to have a full site survey, they must
            ensure that the recorded OSI map of the property reflects actual
            boundaries. Our Engineers will produce the PRA based on the OSI Map
            and not survey the plot - NOTE: The Land registry is not conclusive
            on boundaries and the onus is on the property owner to have correct
            boundaries.
          </p>
          <br />
          <p>
            Should any additional documents be needed or queries need to be
            answered, we will quote for such work before undertaking it.
          </p>
          <br />
          <p>
            Our 7-day turnaround guarantee is based on 7 working days. We will
            ensure that the PDF is emailed within this time and the Map is
            posted to the Client. Should you require a site survey, we will need
            access to the site as required and should there be an issue with
            access or availability of times / days for access this may change
            the timeframe for delivery of the map / maps.
          </p>
        </ScrollView>
      </Popup>
    </>
  );
};

export default QuoteGeneration;
