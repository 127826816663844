import 'devextreme/dist/css/dx.common.css';
import '../themes/generated/theme.base.css';
import '../themes/generated/theme.additional.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import HomePage from '../pages/home/Home';
import InternalServerError from '../pages/internal-server-error/InternalServerError';
import PageNotFoundError from '../pages/page-not-found/PageNotFound';
import SuccessPage from '../pages/success/Success';

import Logo from '../assets/icons/logo.png';

// State
import data from '../appData.json';
import { useAppState, stateActions } from '../hooks/use-app-state';

import classes from './App.module.scss';

const App = () => {
  const { appDispatch } = useAppState();

  useEffect(() => {
    const appData: IAppData = {
      boundaryLines: data.boundaryLines,
      contactTypes: data.contactTypes,
      counties: data.counties,
      mapTypes: data.mapTypes,
      propertySizes: data.propSizes,
      propertyTypes: data.propertyTypes,
      siteSurveyPrice: data.siteSurveyPrice,
    };

    appDispatch({
      type: stateActions.STATE_INIT,
      payload: appData,
    });
  }, [appDispatch]);

  return (
    <>
      <header className={classes.header}>
        <a href="https://www.propertymaps.ie">
          <img src={Logo} alt="logo" />
        </a>
      </header>
      <section className={classes.wrapper}>
        <Switch>
          <Route component={SuccessPage} path="/success" />
          <Route component={InternalServerError} path="/500" />
          <Route component={HomePage} path="/" exact />
          <Route component={PageNotFoundError} />
          <Route />
        </Switch>
      </section>
    </>
  );
};

export default App;
