import React from 'react';
import { motion } from 'framer-motion';

interface IAnimatedDiv {
  className?: string;
  duration?: number;
}

const AnimatedDiv: React.FC<IAnimatedDiv> = ({
  children,
  className,
  duration = 0.75,
}) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      transition={{ duration }}
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedDiv;
