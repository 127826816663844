import React from 'react';
import classes from './OrderSection.module.scss';

interface OrderSectionProps {
  title: string;
  visible?: boolean;
}

const OrderSection = ({
  children,
  title,
  visible = true,
}: React.PropsWithChildren<OrderSectionProps>) => {
  return visible ? (
    <section className={classes.wrapper}>
      <h3>{title}</h3>
      <div className={classes.content}>{children}</div>
    </section>
  ) : (
    <section />
  );
};

export default OrderSection;
