import React, { createContext, useContext, useReducer } from 'react';

// icons
import ResidentialIcon from '../assets/icons/residential.svg';
import CommercialIcon from '../assets/icons/commercial.svg';
import IndustrialIcon from '../assets/icons/industrial.svg';
import AgriculturalIcon from '../assets/icons/agricultural.svg';
import StandardImage from '../assets/icons/standard.svg';
import NonStandardImage from '../assets/icons/non-standard.svg';
import ComplexImage from '../assets/icons/complex.svg';

import {
  contactTypeConstants,
  formConstants,
  initialValueConstants,
} from '../utils/constants';

interface IAction {
  type: string;
  payload?: any;
}

const initialState: IAppState = {
  appData: {
    boundaryLines: [],
    contactTypes: [],
    counties: [],
    mapTypes: [],
    propertySizes: [],
    propertyTypes: [],
    siteSurveyPrice: 0,
  },
  formData: {
    mapType: '',
    propertyAddr1: '',
    propertyTown: '',
    propertyCounty: '',
    contactType: 'Individual',
    clientName: '',
    clientEmail: '',
    sendMapTo: 'Client',
    withSiteSurvey: false,
    price: 0,
    payer: 'Client',
  },
  withMultistorey: false,
  isComplexMap: false,
  mapTypePrice: 0,
  propertyAddrPrice: 0,
  boundaryLinePrice: 0,
  file: [],
  totalPrice: 0,
  totalPriceWithSurvey: 0,
  multistoreyPrice: 0,
  termsAgreed: false,
};

export const stateActions = {
  STATE_INIT: 'STATE_INIT',
  STATE_FILE_VALUE_CHANGED: 'STATE_FILE_VALUE_CHANGED',
  STATE_FORM_VALUE_CHANGED: 'STATE_FORM_VALUE_CHANGED',
  STATE_MAP_TYPE_CHANGED: 'STATE_MAP_TYPE_CHANGED',
  STATE_PROPERTY_TYPE_VALUE_CHANGED: 'STATE_PROPERTY_TYPE_VALUE_CHANGED',
  STATE_TERMS_AGREED_CHANGED: 'STATE_TERMS_AGREED_CHANGED',
};

const appStateContext = createContext<{
  appDispatch: React.Dispatch<IAction>;
  appState: IAppState;
}>({
  appDispatch: () => null,
  appState: initialState,
});

const reducer = (state: IAppState, action: IAction): IAppState => {
  switch (action.type) {
    case stateActions.STATE_INIT: {
      const {
        boundaryLines,
        contactTypes,
        counties,
        mapTypes,
        propertySizes,
        propertyTypes,
        siteSurveyPrice,
      } = action.payload;

      // Get Initial Boundary Line
      const initialBoundaryLine = (boundaryLines as IBoundaryLine[]).find(
        (b) => b.code === initialValueConstants.INITIAL_BOUNDARY_LINE
      );
      if (!initialBoundaryLine) return state;

      // Get Initial Map Type
      const initialMapType = (mapTypes as IMapType[]).find(
        (m) => m.code === initialValueConstants.INITIAL_MAP_TYPE
      );
      if (!initialMapType) return state;

      // Get Initial Property Type
      const initialPropType = (propertyTypes as IPropertyType[]).find(
        (p) => p.text === initialValueConstants.INITIAL_PROP_TYPE
      );
      if (!initialPropType) return state;

      const totalPrice = initialMapType.price + initialBoundaryLine.price;

      return {
        ...state,
        appData: {
          boundaryLines: boundaryLines.map((b: IBoundaryLine) => {
            let icon = StandardImage;

            // if (b.code === 'Standard - Rectangular') {
            //   icon =
            // }

            if (b.code === 'Off Standard') {
              icon = NonStandardImage;
            } else if (b.code === 'Complex') {
              icon = ComplexImage;
            }

            return { ...b, icon };
          }),
          contactTypes,
          counties,
          mapTypes,
          propertySizes,
          propertyTypes: propertyTypes.map((p: IPropertyType) => {
            let icon = ResidentialIcon;

            if (p.icon === 'commercial') {
              icon = CommercialIcon;
            } else if (p.icon === 'industrial') {
              icon = IndustrialIcon;
            } else if (p.icon === 'agricultural') {
              icon = AgriculturalIcon;
            }

            return { ...p, icon };
          }),
          siteSurveyPrice,
        },
        formData: {
          ...state.formData,
          boundaryLine: initialBoundaryLine.code,
          mapType: initialMapType.code,
          propertyType: initialPropType.text,
        },
        mapTypePrice: initialMapType.price,
        boundaryLinePrice: initialBoundaryLine.price,
        totalPrice,
        totalPriceWithSurvey: totalPrice + siteSurveyPrice,
      };
    }
    case stateActions.STATE_FILE_VALUE_CHANGED: {
      const file = action.payload as File[];
      return {
        ...state,
        formData: {
          ...state.formData,
          imageUrl: file[0].name,
        },
        file,
      };
    }
    case stateActions.STATE_FORM_VALUE_CHANGED: {
      const { field, value } = action.payload;

      if (!field) return state;
      let formData = { ...state.formData, [field]: value };
      let isComplexMap = state.isComplexMap;
      let withMultistorey = state.withMultistorey;
      let mapTypePrice = state.mapTypePrice;
      let propertyAddrPrice = state.propertyAddrPrice;
      let boundaryLinePrice = state.boundaryLinePrice;
      let payer = state.formData.payer;
      let sendMapTo = state.formData.sendMapTo;
      let boundaryLine = state.formData.boundaryLine;
      let multistoreyPrice = state.multistoreyPrice;

      if (field === formConstants.FORM_MAP_TYPE) {
        const item = state.appData.mapTypes.find((m) => m.code === value);
        if (item) {
          mapTypePrice = item.price;
          withMultistorey = item.withMultistorey;
          isComplexMap = item.withMultistorey
            ? false
            : boundaryLine === 'Complex'
            ? true
            : false;
          formData = { ...formData, boundaryLine, withSiteSurvey: true };
        }
      } else if (field === formConstants.FORM_BOUNDARY_LINE) {
        const item = state.appData.boundaryLines.find((b) => b.code === value);

        if (item) {
          boundaryLinePrice = item.price;
          isComplexMap = item.isComplex;
        }
      } else if (field === formConstants.FORM_PROPERTY_COUNTY) {
        const item = state.appData.counties.find((c) => c.name === value);
        if (item && item.province !== 'leinster') {
          propertyAddrPrice = 100;
        } else {
          propertyAddrPrice = 0;
        }
      } else if (field === formConstants.FORM_PAYER) {
        payer = value;
      } else if (field === formConstants.FORM_CONTACT_TYPE) {
        if (value === contactTypeConstants.CONTACT_TYPE_SOLICITOR) {
          if (payer !== 'Client') {
            payer = 'Solicitor';
          }

          if (sendMapTo !== 'Client') {
            sendMapTo = 'Solicitor';
          }
        } else if (value === contactTypeConstants.CONTACT_TYPE_AUCTIONEER) {
          if (payer !== 'Client') {
            payer = 'Auctioneer';
          }

          if (sendMapTo !== 'Client') {
            sendMapTo = 'Auctioneer';
          }
        } else {
          payer = 'Client';
          sendMapTo = 'Client';
        }
      } else if (field === formConstants.FORM_SEND_MAP_TO) {
        sendMapTo = value;
      } else if (field === formConstants.FORM_PROPERTY_SIZE) {
        const propSize = state.appData.propertySizes.find(
          (p) => p.name === value
        );
        if (propSize) {
          multistoreyPrice = propSize.price;
        }
      }

      let totalPrice = boundaryLinePrice + mapTypePrice + propertyAddrPrice;
      totalPrice = withMultistorey
        ? totalPrice -
          boundaryLinePrice -
          state.appData.siteSurveyPrice +
          multistoreyPrice
        : totalPrice;

      return {
        ...state,
        formData: {
          ...formData,
          payer,
          sendMapTo,
        },
        isComplexMap,
        withMultistorey,
        boundaryLinePrice,
        mapTypePrice,
        propertyAddrPrice,
        totalPrice: isComplexMap ? 0 : totalPrice,
        totalPriceWithSurvey: isComplexMap
          ? 0
          : totalPrice + state.appData.siteSurveyPrice,
        multistoreyPrice,
      };
    }
    case stateActions.STATE_PROPERTY_TYPE_VALUE_CHANGED: {
      const property = state.appData.propertyTypes.find(
        (p) => p.text === action.payload
      );

      if (!property) return state;

      let boundaryLine = property.isComplex
        ? 'Complex'
        : state.formData.boundaryLine;

      if (
        property.text.toLowerCase().includes('residential') &&
        !state.formData.boundaryLine
      ) {
        boundaryLine = '';
      }

      return {
        ...state,
        appData: {
          ...state.appData,
          boundaryLines: state.appData.boundaryLines.map((b) => ({
            ...b,
            disabled: property.isComplex ? true : false,
          })),
        },
        formData: {
          ...state.formData,
          propertyType: property.text,
          boundaryLine,
        },
        isComplexMap:
          property.isComplex || state.formData.boundaryLine === 'Complex',
        totalPrice: property.isComplex ? 0 : state.totalPrice,
        totalPriceWithSurvey: property.isComplex
          ? 0
          : state.totalPriceWithSurvey,
      };
    }
    case stateActions.STATE_TERMS_AGREED_CHANGED: {
      return {
        ...state,
        termsAgreed: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

function useProvideAppState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { appDispatch: dispatch, appState: state };
}

export const ProvideAppState: React.FC = ({ children }) => {
  const appState = useProvideAppState();
  return (
    <appStateContext.Provider value={appState}>
      {children}
    </appStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(appStateContext);
};
