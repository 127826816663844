import React, { useEffect, useState } from 'react';

// DX
import Button from 'devextreme-react/button';
import FileUploader from 'devextreme-react/file-uploader';
import RadioGroup from 'devextreme-react/radio-group';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import { Validator, RequiredRule } from 'devextreme-react/validator';

// components
import { AnimatedDiv, ButtonGroup, FormSection, Label } from '../';

// constants
import { formConstants } from '../../utils/constants';

// state
import { stateActions, useAppState } from '../../hooks/use-app-state';

// styles
import classes from './InstantQuote.module.scss';

const duration = 0.5;

interface IInstantQuoteProps {
  onNextClick: () => void;
}

const InstantQuote: React.FC<IInstantQuoteProps> = ({ onNextClick }) => {
  const { appState, appDispatch } = useAppState();
  const [boundaryLines, setBoundaryLines] = useState<IBoundaryLine[]>([]);

  const [doValidation, setDoValidation] = useState(false);

  const onBoundryLineChangedHandler = (e: any) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field: 'boundaryLine', value: e },
    });
  };

  const onPropTypeChangedHandler = (e: string) => {
    appDispatch({
      type: stateActions.STATE_PROPERTY_TYPE_VALUE_CHANGED,
      payload: e,
    });
  };

  const onFileValueChangedHandler = (e: any) => {
    appDispatch({
      type: stateActions.STATE_FILE_VALUE_CHANGED,
      payload: e.value,
    });
  };

  const onFormValueChangedHandler = (field: string, value: string | File) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field, value },
    });
  };

  useEffect(() => {
    if (appState.appData.boundaryLines.length > 0) {
      // const tooltips: { [key: string]: any } = {
      //   'Standard - Rectangular': (
      //     <div>Boundaries of plot are straight lines and it is 4 sided</div>
      //   ),
      //   'Off Standard': (
      //     <div>
      //       Boundaries of plot are rectangular with minor irregularities
      //     </div>
      //   ),
      //   Complex: <div>Boundaries are varied and complex</div>,
      // };

      const items: IBoundaryLine[] = appState.appData.boundaryLines.map((b) => {
        return {
          ...b,
        };
      });

      setBoundaryLines(items);
    }
  }, [
    appState.appData.boundaryLines.length,
    appState.appData.boundaryLines,
    appState.isComplexMap,
  ]);

  const onValidateHandler = (e: any) => {
    setDoValidation(true);
    const result = e.validationGroup.validate();

    if (result.isValid) {
      if (appState.isComplexMap && appState.file?.length === 0) {
        notify(
          { message: 'Please upload a File', position: 'top' },
          'error',
          2000
        );
        return;
      }
      onNextClick();
    }
  };

  const customRadioItem = (data: IMapType) => (
    <div className={classes.customRadioItem}>{data.value}</div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FormSection title="Instant Quote">
        <div className={classes.wrapper}>
          <div className={classes.row}>
            <label>Type of Map</label>
            <RadioGroup
              className={classes.editor}
              defaultValue="Standard"
              itemRender={customRadioItem}
              items={appState.appData.mapTypes}
              displayExpr="value"
              valueExpr="code"
              value={appState.formData.mapType}
              onValueChanged={(e: any) =>
                onFormValueChangedHandler(formConstants.FORM_MAP_TYPE, e.value)
              }
            />
          </div>
          {!appState.withMultistorey && (
            <>
              <AnimatedDiv className={classes.row} duration={duration}>
                <Label>Type of Property</Label>
                <ButtonGroup
                  className={classes.editor}
                  items={appState.appData.propertyTypes}
                  valueExpr="text"
                  value={appState.formData.propertyType}
                  onSelectedChanged={onPropTypeChangedHandler}
                />
              </AnimatedDiv>
              {!appState.formData.propertyType && doValidation && (
                <AnimatedDiv className={classes.row}>
                  <label></label>
                  <div className={`${classes.editor} ${classes.error}`}>
                    <span>Please select a Property Type</span>
                  </div>
                </AnimatedDiv>
              )}
            </>
          )}
          {appState.withMultistorey && (
            <AnimatedDiv className={classes.row} duration={duration}>
              <Label>Estimated Property Size</Label>
              <SelectBox
                className={classes.editor}
                dataSource={appState.appData.propertySizes}
                displayExpr="name"
                searchEnabled
                showDataBeforeSearch
                validationMessageMode="always"
                value={appState.formData.propertySize}
                valueExpr="name"
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_SIZE,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select an Estimated Property Size" />
                </Validator>
              </SelectBox>
            </AnimatedDiv>
          )}
          <div className={classes.row}>
            <label>Property Address</label>
            <div className={classes.editor}>
              <TextBox
                className={classes.textBox}
                placeholder="Address 1"
                value={appState.formData.propertyAddr1}
                valueChangeEvent="keyup"
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_ADDR1,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter an Address" />
                </Validator>
              </TextBox>
              <TextBox
                className={classes.textBox}
                placeholder="Address Line 2 (Optional)"
                value={appState.formData.propertyAddr2}
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_ADDR2,
                    e.value
                  )
                }
              />
              <TextBox
                className={classes.textBox}
                placeholder="Town"
                value={appState.formData.propertyTown}
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_TOWN,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Town" />
                </Validator>
              </TextBox>
              <SelectBox
                className={classes.textBox}
                dataSource={appState.appData.counties}
                displayExpr="name"
                placeholder="County"
                searchEnabled
                showDataBeforeSearch
                value={appState.formData.propertyCounty}
                valueExpr="name"
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_COUNTY,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select a County" />
                </Validator>
              </SelectBox>
              <TextBox
                placeholder="Eircode (Optional)"
                value={appState.formData.clientEircode}
                onValueChanged={(e: any) =>
                  onFormValueChangedHandler(
                    formConstants.FORM_PROPERTY_EIRCODE,
                    e.value
                  )
                }
              />
            </div>
          </div>
          {!appState.withMultistorey && (
            <AnimatedDiv className={classes.row} duration={duration}>
              <label htmlFor="">Boundary Lines</label>
              <ButtonGroup
                className={classes.editor}
                items={boundaryLines}
                textExpr="code"
                value={appState.formData.boundaryLine}
                valueExpr="code"
                onSelectedChanged={onBoundryLineChangedHandler}
              />
            </AnimatedDiv>
          )}
          {appState.isComplexMap && (
            <>
              <AnimatedDiv
                className={`${classes.row} ${classes.infoBox}`}
                duration={duration}
              >
                <p className={classes.editor}>
                  Our instant quote is not available for this type of property
                  but please upload a Map of the Property/Plot and we will email
                  you a quote within 24 hours.
                </p>
              </AnimatedDiv>
              <AnimatedDiv className={classes.row}>
                <label htmlFor=""></label>
                <FileUploader
                  accept="image/*"
                  className={classes.editor}
                  multiple={false}
                  uploadMode="useForm"
                  value={appState.file}
                  onValueChanged={onFileValueChangedHandler}
                  readyToUploadMessage="Successfully Uploaded"
                />
              </AnimatedDiv>
            </>
          )}
        </div>
      </FormSection>
      <div className={classes.btnWrapper}>
        {!appState.isComplexMap && (
          <Button
            className={`${classes.btn} ${classes.btnPrimary}`}
            text="Generation of Quote"
            onClick={onValidateHandler}
          >
            <div className={classes.btnContent}>
              <span className="text">Generation of Quote</span>
              <i className="dx-icon dx-icon-chevronright"></i>
            </div>
          </Button>
        )}
        {appState.isComplexMap && (
          <Button
            className={`${classes.btn} ${classes.btnPrimary}`}
            text="Generation of Quote"
            onClick={onValidateHandler}
          >
            <div className={classes.btnContent}>
              <span className="text">
                {appState.isComplexMap ? 'Next Step' : 'Complete Your Order'}
              </span>
              <i className="dx-icon dx-icon-chevronright"></i>
            </div>
          </Button>
        )}
      </div>
    </>
  );
};

export default InstantQuote;
