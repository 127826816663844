import { useState } from 'react';
import { restApi } from '../../axiosConfig';

const usePost = () => {
  const [loading, setLoading] = useState(false);

  const lazyPost = async (url: string, data: any = {}) => {
    try {
      setLoading(true);
      const response = await restApi.post(url, data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.response) {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { lazyPost, loading };
};

export default usePost;
