import axios from 'axios';

const restApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// restApi.interceptors.request.use((config) => {
//     const token = localStorage.getItem('pmaps:accessToken');

//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
// });

export { restApi };