import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from 'devextreme-react/button';

import PageNotFoundImage from '../../assets/images/page_not_found.svg';

import classes from './PageNotFound.module.scss';

const PageNotFound: React.FC = () => {
  const [redirect, setRedirect] = useState('');
  const onHomeButtonClicked = () => {
    setRedirect('/');
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <div className={classes.wrapper}>
        <img src={PageNotFoundImage} alt="" />
        <h1>
          Oops, looks like the page you requested <br /> could not be found.
        </h1>
        <Button
          className={`${classes.btn} ${classes.btnPrimary}`}
          text="Go Home"
          onClick={onHomeButtonClicked}
        ></Button>
      </div>
    </>
  );
};

export default PageNotFound;
