const INITIAL_BOUNDARY_LINE = 'Standard';
const INITIAL_MAP_TYPE = 'Standard';
const INITIAL_PROP_TYPE = 'Residential';

const CONTACT_TYPE_AUCTIONEER = "Auctioneer";
const CONTACT_TYPE_INDIVIDUAL = "Individual";
const CONTACT_TYPE_OTHER = "Other";
const CONTACT_TYPE_SOLICITOR = "Solicitor";

const PROP_TYPE_RESIDENTIAL = 'Residential';
const FORM_CONTACT_TYPE = 'contactType';
const FORM_BOUNDARY_LINE = 'boundaryLine';
const FORM_COMPLEX = 'Complex';
const FORM_MAP_TYPE = 'mapType';
const FORM_PROPERTY_TYPE = 'propertyType';
const FORM_PROPERTY_SIZE = 'propertySize';
const FORM_PROPERTY_ADDR1 = 'propertyAddr1';
const FORM_PROPERTY_ADDR2 = 'propertyAddr2';
const FORM_PROPERTY_TOWN = 'propertyTown';
const FORM_PROPERTY_COUNTY = 'propertyCounty';
const FORM_PROPERTY_EIRCODE = 'propertyEircode';
const FORM_CLIENT_NAME = 'clientName';
const FORM_CLIENT_ADDR1 = 'clientAddr1';
const FORM_CLIENT_ADDR2 = 'clientAddr2';
const FORM_CLIENT_TOWN = 'clientTown';
const FORM_CLIENT_COUNTY = 'clientCounty';
const FORM_CLIENT_EIRCODE = 'clientEircode';
const FORM_CLIENT_CONTACT_NUMBER = 'clientContactNumber';
const FORM_CLIENT_EMAIL = 'clientEmail';
const FORM_SOLICITOR_NAME = 'solicitorName';
const FORM_SOLICITOR_ADDR1 = 'solicitorAddr1';
const FORM_SOLICITOR_ADDR2 = 'solicitorAddr2';
const FORM_SOLICITOR_TOWN = 'solicitorTown';
const FORM_SOLICITOR_COUNTY = 'solicitorCounty';
const FORM_SOLICITOR_EIRCODE = 'solicitorEircode';
const FORM_SOLICITOR_CONTACT_NUMBER = 'solicitorContactNumber';
const FORM_SOLICITOR_CONTACT_NAME = 'solicitorContactName';
const FORM_SOLICITOR_EMAIL = 'solicitorEmail';
const FORM_AUCTIONEER_NAME = 'auctioneerName';
const FORM_AUCTIONEER_ADDR1 = 'auctioneerAddr1';
const FORM_AUCTIONEER_ADDR2 = 'auctioneerAddr2';
const FORM_AUCTIONEER_TOWN = 'auctioneerTown';
const FORM_AUCTIONEER_COUNTY = 'auctioneerCounty';
const FORM_AUCTIONEER_EIRCODE = 'auctioneerEircode';
const FORM_AUCTIONEER_CONTACT_NUMBER = 'auctioneerContactNumber';
const FORM_AUCTIONEER_CONTACT_NAME = 'auctioneerContactName';
const FORM_AUCTIONEER_EMAIL = 'auctioneerEmail';
const FORM_SEND_MAP_TO = 'sendMapTo';
const FORM_PAYER = 'payer';


export const formConstants = {
    PROP_TYPE_RESIDENTIAL,
    FORM_PROPERTY_TYPE,
    FORM_PROPERTY_SIZE,
    FORM_CONTACT_TYPE,
    FORM_BOUNDARY_LINE,
    FORM_COMPLEX,
    FORM_MAP_TYPE,
    FORM_PROPERTY_ADDR1,
    FORM_PROPERTY_ADDR2,
    FORM_PROPERTY_TOWN,
    FORM_PROPERTY_COUNTY,
    FORM_PROPERTY_EIRCODE,
    FORM_CLIENT_NAME,
    FORM_CLIENT_ADDR1,
    FORM_CLIENT_ADDR2,
    FORM_CLIENT_TOWN,
    FORM_CLIENT_COUNTY,
    FORM_CLIENT_EIRCODE,
    FORM_CLIENT_CONTACT_NUMBER,
    FORM_CLIENT_EMAIL,
    FORM_SOLICITOR_NAME,
    FORM_SOLICITOR_ADDR1,
    FORM_SOLICITOR_ADDR2,
    FORM_SOLICITOR_TOWN,
    FORM_SOLICITOR_COUNTY,
    FORM_SOLICITOR_EIRCODE,
    FORM_SOLICITOR_CONTACT_NUMBER,
    FORM_SOLICITOR_CONTACT_NAME,
    FORM_SOLICITOR_EMAIL,
    FORM_AUCTIONEER_NAME,
    FORM_AUCTIONEER_ADDR1,
    FORM_AUCTIONEER_ADDR2,
    FORM_AUCTIONEER_TOWN,
    FORM_AUCTIONEER_COUNTY,
    FORM_AUCTIONEER_EIRCODE,
    FORM_AUCTIONEER_CONTACT_NUMBER,
    FORM_AUCTIONEER_CONTACT_NAME,
    FORM_AUCTIONEER_EMAIL,
    FORM_SEND_MAP_TO,
    FORM_PAYER
};


export const initialValueConstants = {
    INITIAL_BOUNDARY_LINE,
    INITIAL_MAP_TYPE,
    INITIAL_PROP_TYPE,
};

export const contactTypeConstants = {
    CONTACT_TYPE_AUCTIONEER,
    CONTACT_TYPE_INDIVIDUAL,
    CONTACT_TYPE_OTHER,
    CONTACT_TYPE_SOLICITOR
}