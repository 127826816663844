import React, { useState } from 'react';

// Components
import { CompleteOrder, InstantQuote, QuoteGeneration } from '../../components';

import { useAppState } from '../../hooks/use-app-state';

// Styles
import classes from './Home.module.scss';

const Home: React.FC = () => {
  const { appState } = useAppState();
  const [currentSection, setCurrentSection] = useState(0);

  const onNextClickHandler = () => {
    let nextSection = currentSection + 1;
    if (currentSection === 0 && appState.isComplexMap) {
      nextSection = 2;
    }
    setCurrentSection(nextSection);
  };

  const onPreviousClickHandler = () => {
    let prevSection = currentSection - 1;
    if (currentSection === 2 && appState.isComplexMap) {
      prevSection = 0;
    }

    setCurrentSection(prevSection);
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* <button type="button" onClick={() => console.log(appState)}>
        State
      </button> */}
      {process.env.REACT_APP_ENVIRONMENT !== 'Production' && (
        <span className={classes.test}>** Test Version **</span>
      )}
      {currentSection === 0 && (
        <InstantQuote onNextClick={onNextClickHandler} />
      )}
      {currentSection === 1 && (
        <QuoteGeneration
          onNextClick={onNextClickHandler}
          onPreviousClick={onPreviousClickHandler}
        />
      )}
      {currentSection === 2 && (
        <CompleteOrder onPreviousClick={onPreviousClickHandler} />
      )}
    </form>
  );
};

export default Home;
