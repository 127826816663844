import React from 'react';

// dx
import RadioGroup from 'devextreme-react/radio-group';

// state
import { useAppState, stateActions } from '../../hooks/use-app-state';

// style
import classes from './PayerSelection.module.scss';

interface PayerSelectionProps {
  className?: string | undefined;
  payer: 'Auctioneer' | 'Solicitor';
}

const customRadioItem = (data: any) => (
  <div className={classes.customRadioItem}>{data.text}</div>
);

const PayerSelection = ({ className, payer }: PayerSelectionProps) => {
  const { appDispatch, appState } = useAppState();

  const onValueChangedHandler = ({ value }: any) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field: 'payer', value },
    });
  };
  return (
    <>
      <RadioGroup
        className={className}
        displayExpr="text"
        items={[
          {
            code: 'Client',
            text:
              'Please Email our Client the link for Payment to Complete Order',
          },
          {
            code: payer,
            text: 'We will Complete the Payment for our Client',
          },
        ]}
        itemRender={customRadioItem}
        value={appState.formData.payer || payer}
        valueExpr="code"
        onValueChanged={onValueChangedHandler}
      />
    </>
  );
};

export default PayerSelection;
