import React from 'react';

import ErrorImage from '../../assets/images/bug_fixing.svg';

import classes from './InternalServerError.module.scss';

const InternalServerError: React.FC = () => {
  return (
    <div className={classes.wrapper}>
      <img src={ErrorImage} alt="" />
      <h1>Oops, looks like something went wrong.</h1>
      <p>
        We have been notified of the issue and are working to resolve it. <br />
        Please try again later.
      </p>
    </div>
  );
};

export default InternalServerError;
