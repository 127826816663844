import React from 'react';

// DX
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import {
  CompareRule,
  EmailRule,
  RequiredRule,
  Validator,
} from 'devextreme-react/validator';

// Components
import { AnimatedDiv, OrderSection } from '../';

// State
import { stateActions, useAppState } from '../../hooks/use-app-state';

// Utils
import { formConstants } from '../../utils/constants';

// Styles
import classes from './IndividualContactInfo.module.scss';

const IndividualAddress: React.FC = () => {
  const { appDispatch, appState } = useAppState();

  const onValueChangedHandler = (field: string, value: string) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field, value },
    });
  };

  return (
    <AnimatedDiv className={classes.wrapper} duration={0.5}>
      <div className={classes.col}>
        <OrderSection title="Contact Details">
          <div className={classes.row}>
            <label>Name</label>
            <TextBox
              className={classes.editor}
              placeholder="Name"
              value={appState.formData.clientName}
              onValueChanged={(e: any) =>
                onValueChangedHandler(formConstants.FORM_CLIENT_NAME, e.value)
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Name" />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label>Address</label>
            <div className={classes.editor}>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 1"
                value={appState.formData.clientAddr1}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_CLIENT_ADDR1,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter an Address Line 1" />
                </Validator>
              </TextBox>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 2 (Optional)"
                value={appState.formData.clientAddr2}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_CLIENT_ADDR2,
                    e.value
                  )
                }
              />
              <TextBox
                className={classes.spacer}
                placeholder="Town"
                value={appState.formData.clientTown}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(formConstants.FORM_CLIENT_TOWN, e.value)
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Town" />
                </Validator>
              </TextBox>
              <SelectBox
                className={classes.spacer}
                dataSource={appState.appData.counties}
                displayExpr="name"
                placeholder="County"
                searchEnabled
                showDataBeforeSearch
                valueExpr="name"
                value={appState.formData.clientCounty}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_CLIENT_COUNTY,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select a County" />
                </Validator>
              </SelectBox>
              <TextBox
                placeholder="Eircode (Optional)"
                value={appState.formData.clientEircode}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_CLIENT_EIRCODE,
                    e.value
                  )
                }
              />
            </div>
          </div>
          <div className={classes.row}>
            <label htmlFor="">Email</label>
            <TextBox
              className={classes.editor}
              placeholder="Email"
              inputAttr={{ type: 'email' }}
              value={appState.formData.clientEmail}
              onValueChanged={(e: any) =>
                onValueChangedHandler(formConstants.FORM_CLIENT_EMAIL, e.value)
              }
            >
              <Validator>
                <RequiredRule message="Please enter an Email Address" />
                <EmailRule message="Please enter a valid Email Address" />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label htmlFor="">Confirm Email</label>
            <TextBox
              className={classes.editor}
              placeholder="Confirm Email"
              inputAttr={{ type: 'email' }}
            >
              <Validator>
                <RequiredRule message="Please confirm Email Address" />
                <EmailRule message="Please enter a valid Email Address" />
                <CompareRule
                  message="Email addresses do not match"
                  comparisonTarget={() => appState.formData.clientEmail}
                />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label htmlFor="">Contact Number</label>
            <TextBox
              className={classes.editor}
              placeholder="Contact Number"
              value={appState.formData.clientContactNumber}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_CLIENT_CONTACT_NUMBER,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Contact Number" />
              </Validator>
            </TextBox>
          </div>
        </OrderSection>
        <OrderSection
          title="Solicitor"
          visible={appState.formData.sendMapTo === 'Solicitor'}
        >
          <div className={classes.row}>
            <label>Name</label>
            <TextBox
              className={classes.editor}
              placeholder="Solicitor Name"
              value={appState.formData.solicitorName}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_SOLICITOR_NAME,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Name" />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label>Address</label>
            <div className={classes.editor}>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 1"
                value={appState.formData.solicitorAddr1}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_ADDR1,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter an Address Line 1" />
                </Validator>
              </TextBox>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 2 (Optional)"
                value={appState.formData.solicitorAddr2}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_ADDR2,
                    e.value
                  )
                }
              />
              <TextBox
                className={classes.spacer}
                placeholder="Town"
                value={appState.formData.solicitorTown}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_TOWN,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Town" />
                </Validator>
              </TextBox>
              <SelectBox
                className={classes.spacer}
                dataSource={appState.appData.counties}
                displayExpr="name"
                placeholder="County"
                searchEnabled
                showDataBeforeSearch
                value={appState.formData.solicitorCounty}
                valueExpr="name"
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_COUNTY,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select a County" />
                </Validator>
              </SelectBox>
              <TextBox
                placeholder="Eircode (Optional)"
                value={appState.formData.solicitorEircode}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_EIRCODE,
                    e.value
                  )
                }
              />
            </div>
          </div>
        </OrderSection>
      </div>
    </AnimatedDiv>
  );
};

export default IndividualAddress;
