import React from 'react';

import classes from './ButtonGroup.module.scss';

// Components
import ButtonGroupItem from '../button-group-item/ButtonGroupItem';

const ButtonGroup: React.FC<IButtonGroupProps> = ({
  className,
  height,
  iconExpr,
  items,
  textExpr,
  value,
  valueExpr,
  onSelectedChanged,
}) => {
  const onButtonClickHandler = (e: any) => {
    if (onSelectedChanged) onSelectedChanged(e);
  };

  return (
    <div className={`${classes.container} ${className}`} style={{ height }}>
      {items.map((item) => (
        <ButtonGroupItem
          iconExpr={iconExpr}
          item={item}
          key={item[valueExpr]}
          textExpr={textExpr}
          value={value}
          valueExpr={valueExpr}
          onClick={() => onButtonClickHandler(item[valueExpr])}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
