import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Redirect } from 'react-router-dom';

import useLazyPost from '../../hooks/axios/useLazyPost';

// DX
import Button from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import {
  CompareRule,
  EmailRule,
  RequiredRule,
  Validator,
} from 'devextreme-react/validator';

// Components
import {
  AuctioneerContactInfo,
  FormSection,
  IndividualContactInfo,
  SolicitorContactInfo,
} from '../';

import StripeLogo from '../../assets/images/stripe-logo.png';

// State
import { useAppState, stateActions } from '../../hooks/use-app-state';

// Utils
import { contactTypeConstants, formConstants } from '../../utils/constants';

import classes from './CompleteOrder.module.scss';
import PayerSelection from '../payer-selection/PayerSelection';
import OrderSection from '../order-section/OrderSection';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);
const url = `${process.env.REACT_APP_API_URL}`;

interface ICompleteOrderProps {
  onPreviousClick: () => void;
}

const CompleteOrder: React.FC<ICompleteOrderProps> = ({ onPreviousClick }) => {
  const { appDispatch, appState } = useAppState();
  const { lazyPost, loading } = useLazyPost();
  const [redirect, setRedirect] = useState('');

  // Derived State
  const price = appState.formData.withSiteSurvey
    ? appState.totalPriceWithSurvey
    : appState.totalPrice - appState.propertyAddrPrice;

  const onFormValueChangedHandler = (field: string, value: string) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field, value },
    });
  };

  const customRadioItem = (data: any) => (
    <div className={classes.customRadioItem}>{data.text}</div>
  );

  const buttonText =
    price > 0
      ? `COMPLETE ORDER (€${(price + price * 0.23).toFixed(2)})`
      : 'SEND QUOTE REQUEST';

  const onValueChangedHandler = (field: string, value: string) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field, value },
    });
  };

  const onCompleteOrderClick = async (e: any) => {
    const result = e.validationGroup.validate();
    if (result.isValid) {
      if (price > 0) {
        const iPay =
          appState.formData.contactType === appState.formData.payer ||
          appState.formData.contactType ===
            contactTypeConstants.CONTACT_TYPE_INDIVIDUAL ||
          appState.formData.contactType ===
            contactTypeConstants.CONTACT_TYPE_OTHER;

        if (iPay) {
          // Stripe Checkout
          const stripe = await stripePromise;
          if (stripe) {
            try {
              const session = await lazyPost(`/quote/checkout`, {
                ...appState.formData,
                price,
              });
              if (session && session.id) {
                const result = await stripe.redirectToCheckout({
                  sessionId: session.id,
                });
                if (result && result.error) {
                }
              }
            } catch (error) {
              if (error.response.status === 500) {
                setRedirect('/500');
              }
            }
          }
        } else {
          // Send invoice
          try {
            const invoice = await lazyPost(`/quote/invoice`, {
              ...appState.formData,
              price,
            });
            setRedirect(`/success?bookingRef=${invoice.bookingRef}`);
          } catch (error) {
            setRedirect('/500');
          }
        }
      } else {
        // Complex Map Type
        if (appState.file) {
          let formData = new FormData();
          formData.append('file', appState.file[0]);

          try {
            let result = await lazyPost(`${url}/quote`, {
              ...appState.formData,
              price: 0,
            });

            if (result && result.bookingRef) {
              // Upload file
              const fileName = `${result.bookingRef}_${appState.file[0].name}`;
              await lazyPost(`/file/${fileName}`, formData);

              setRedirect(
                `/success?bookingRef=${result.bookingRef}&quote=true`
              );
            }
          } catch (error) {
            setRedirect('/500');
          }
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <FormSection
        title={appState.isComplexMap ? 'Request Quote' : 'Complete Your Order'}
      >
        <div className={classes.wrapper}>
          {price > 0 && (
            <>
              <header>
                <h3>Thank you for Choosing PropertyMaps.ie</h3>

                <p>
                  We need a little more information in order to send your
                  completed map to the correct address. <br /> We also offer a
                  special checkout process for Solicitors/Auctioneers that
                  allows them to order the map and their client to complete the
                  purchase.
                </p>
              </header>
              <div className={classes.col}>
                <OrderSection
                  title={
                    appState.isComplexMap
                      ? 'Contact Information for Quote'
                      : 'Order Details'
                  }
                >
                  <div className={classes.row}>
                    <label htmlFor="">Contact Type</label>
                    <SelectBox
                      className={classes.editor}
                      dataSource={appState.appData.contactTypes}
                      value={appState.formData.contactType}
                      onValueChanged={(e: any) =>
                        onFormValueChangedHandler(
                          formConstants.FORM_CONTACT_TYPE,
                          e.value
                        )
                      }
                    ></SelectBox>
                  </div>
                  {[
                    contactTypeConstants.CONTACT_TYPE_INDIVIDUAL,
                    contactTypeConstants.CONTACT_TYPE_OTHER,
                  ].includes(appState.formData.contactType) && (
                    <div className={classes.row}>
                      <label>Recipient</label>
                      <RadioGroup
                        className={classes.editor}
                        displayExpr="text"
                        items={[
                          {
                            code: 'Client',
                            text:
                              'Send Completed PRA Complaint Map to my Address & Email me the PDF Documents',
                          },
                          {
                            code: 'Solicitor',
                            text:
                              "Send Completed PRA Complaint Map to my Solicitor's Address & Email them the PDF Documents",
                          },
                        ]}
                        itemRender={customRadioItem}
                        value={appState.formData.sendMapTo}
                        valueExpr="code"
                        onValueChanged={(e: any) =>
                          onFormValueChangedHandler(
                            formConstants.FORM_SEND_MAP_TO,
                            e.value
                          )
                        }
                      />
                    </div>
                  )}
                  {[contactTypeConstants.CONTACT_TYPE_SOLICITOR].includes(
                    appState.formData.contactType
                  ) && (
                    <div className={classes.row}>
                      <label>Recipient</label>
                      <RadioGroup
                        className={classes.editor}
                        displayExpr="text"
                        items={[
                          {
                            code: 'Client',
                            text:
                              "Send Completed PRA Complaint Map to my Client's Address & Email them the PDF Documents",
                          },
                          {
                            code: 'Solicitor',
                            text:
                              'Send Completed PRA Complaint Map to my Address & Email me the PDF Documents',
                          },
                        ]}
                        itemRender={customRadioItem}
                        value={appState.formData.sendMapTo}
                        valueExpr="code"
                        onValueChanged={(e: any) =>
                          onFormValueChangedHandler(
                            formConstants.FORM_SEND_MAP_TO,
                            e.value
                          )
                        }
                      />
                    </div>
                  )}
                  {[contactTypeConstants.CONTACT_TYPE_AUCTIONEER].includes(
                    appState.formData.contactType
                  ) && (
                    <div className={classes.row}>
                      <label>Recipient</label>
                      <RadioGroup
                        className={classes.editor}
                        displayExpr="text"
                        items={[
                          {
                            code: 'Client',
                            text:
                              "Send Completed PRA Complaint Map to my Client's Address & Email them the PDF Documents",
                          },
                          {
                            code: 'Auctioneer',
                            text:
                              'Send Completed PRA Complaint Map to my Address & Email me the PDF Documents',
                          },
                        ]}
                        itemRender={customRadioItem}
                        value={appState.formData.sendMapTo}
                        valueExpr="code"
                        onValueChanged={(e: any) =>
                          onFormValueChangedHandler(
                            formConstants.FORM_SEND_MAP_TO,
                            e.value
                          )
                        }
                      />
                    </div>
                  )}
                  {appState.formData.contactType ===
                    contactTypeConstants.CONTACT_TYPE_SOLICITOR &&
                    price > 0 && (
                      <div className={classes.row}>
                        <label>Payment</label>
                        <PayerSelection
                          className={classes.editor}
                          payer="Solicitor"
                        />
                      </div>
                    )}
                  {appState.formData.contactType ===
                    contactTypeConstants.CONTACT_TYPE_AUCTIONEER &&
                    price > 0 && (
                      <div className={classes.row}>
                        <label>Payment</label>
                        <PayerSelection
                          className={classes.editor}
                          payer="Auctioneer"
                        />
                      </div>
                    )}
                </OrderSection>
              </div>

              {(appState.formData.contactType === 'Individual' ||
                appState.formData.contactType === 'Other') && (
                <IndividualContactInfo />
              )}
              {appState.formData.contactType
                .toLowerCase()
                .includes('solicitor') && <SolicitorContactInfo />}
              {appState.formData.contactType
                .toLowerCase()
                .includes('auctioneer') && (
                <AuctioneerContactInfo price={price} />
              )}
            </>
          )}
          {price <= 0 && (
            <>
              <div className={classes.col}>
                <OrderSection title="Order Details">
                  <div className={classes.row}>
                    <label>Name</label>
                    <TextBox
                      className={classes.editor}
                      placeholder="Name"
                      value={appState.formData.clientName}
                      onValueChanged={(e: any) =>
                        onValueChangedHandler(
                          formConstants.FORM_CLIENT_NAME,
                          e.value
                        )
                      }
                    >
                      <Validator>
                        <RequiredRule message="Please enter a Name" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className={classes.row}>
                    <label htmlFor="">Email</label>
                    <TextBox
                      className={classes.editor}
                      placeholder="Email"
                      inputAttr={{ type: 'email' }}
                      value={appState.formData.clientEmail}
                      onValueChanged={(e: any) =>
                        onValueChangedHandler(
                          formConstants.FORM_CLIENT_EMAIL,
                          e.value
                        )
                      }
                    >
                      <Validator>
                        <RequiredRule message="Please enter an Email Address" />
                        <EmailRule message="Please enter a valid Email Address" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className={classes.row}>
                    <label htmlFor="">Confirm Email</label>
                    <TextBox
                      className={classes.editor}
                      placeholder="Confirm Email"
                      inputAttr={{ type: 'email' }}
                    >
                      <Validator>
                        <RequiredRule message="Please confirm Email Address" />
                        <EmailRule message="Please enter a valid Email Address" />
                        <CompareRule
                          message="Email addresses do not match"
                          comparisonTarget={() => appState.formData.clientEmail}
                        />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className={classes.row}>
                    <label htmlFor="">Contact Number</label>
                    <TextBox
                      className={classes.editor}
                      placeholder="Contact Number"
                      value={appState.formData.clientContactNumber}
                      onValueChanged={(e: any) =>
                        onValueChangedHandler(
                          formConstants.FORM_CLIENT_CONTACT_NUMBER,
                          e.value
                        )
                      }
                    >
                      <Validator>
                        <RequiredRule message="Please enter a Contact Number" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className={classes.row}>
                    <label>Notes</label>
                    <TextArea
                      className={classes.editor}
                      placeholder="Please supply any additional information you feel is relevant with regard to the property boundaries or your mapping request."
                      value={appState.formData.notes}
                      onValueChanged={(e: any) =>
                        onFormValueChangedHandler('notes', e.value)
                      }
                    />
                  </div>
                </OrderSection>
              </div>
            </>
          )}
        </div>

        <div className={classes.payButtonWrapper}>
          <Button
            className={`${classes.btn} ${classes.btnPrimary}`}
            disabled={loading}
            text={buttonText}
            onClick={onCompleteOrderClick}
          />
        </div>
        <a href="https://stripe.com/" target="__blank">
          <img
            className={classes.stripeLogo}
            src={StripeLogo}
            alt="Secure Payment Logo"
          />
        </a>
      </FormSection>
      <div className={classes.btnWrapper}>
        {!appState.isComplexMap && (
          <Button
            className={classes.btn}
            icon="chevronleft"
            text="Generation of Quote"
            onClick={onPreviousClick}
          ></Button>
        )}
        {appState.isComplexMap && (
          <Button
            className={classes.btn}
            icon="chevronleft"
            text="Instant Quote"
            onClick={onPreviousClick}
          ></Button>
        )}
      </div>
    </>
  );
};

export default CompleteOrder;
