import React from 'react';
import { motion } from 'framer-motion';

import classes from './FormSection.module.scss';

interface IFormSectionProps {
  title: string;
}

const FormSection: React.FC<IFormSectionProps> = ({ children, title }) => {
  return (
    <motion.section
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.75 }}
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      style={{ width: '100%' }}
    >
      <header>
        <h2 className={classes.title}>{title}</h2>
      </header>
      <div className={classes.content}>{children}</div>
    </motion.section>
  );
};

export default FormSection;
