import React from 'react';

import classes from './Label.module.scss';

interface ILabelProps extends React.HtmlHTMLAttributes<HTMLLabelElement> {
  className?: string;
  required?: boolean;
}

const Label: React.FC<ILabelProps> = ({
  children,
  className,
  required,
  ...rest
}) => {
  return (
    <label aria-required={required} className={`${className}`} {...rest}>
      <span>
        {children} {required && <span className={classes.required}>*</span>}
      </span>
    </label>
  );
};

export default Label;
