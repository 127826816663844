import React from 'react';
import { useLocation } from 'react-router-dom';

import SuccessImage from '../../assets/images/thank_you.svg';

import classes from './Success.module.scss';

const Success: React.FC = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const orderText = query.get('quote') ? 'quote' : 'order';

  return (
    <div className={classes.wrapper}>
      <img src={SuccessImage} alt="" />
      <h1>
        Thank you for choosing{' '}
        <a href="https://www.propertymaps.ie">PropertyMaps.ie</a> <br /> We are
        here for all your property related queries.
      </h1>
      <p>
        Your booking reference is <strong>{query.get('bookingRef')}</strong>.
        You will receive an email <br /> from us confirming your {orderText}.
      </p>
      {/* <p>We will begin working on your property map shortly.</p> */}
    </div>
  );
};

export default Success;
