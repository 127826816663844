import React from 'react';

// DX
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import {
  CompareRule,
  EmailRule,
  RequiredRule,
  Validator,
} from 'devextreme-react/validator';

// Components
import { AnimatedDiv, OrderSection } from '..';

// State
import { stateActions, useAppState } from '../../hooks/use-app-state';

// Utils
import { contactTypeConstants, formConstants } from '../../utils/constants';

// Styles
import classes from './AuctioneerContactInfo.module.scss';

interface AuctioneerContactInfoProps {
  price: number;
}

const AuctioneerContactInfo = ({ price }: AuctioneerContactInfoProps) => {
  const { appDispatch, appState } = useAppState();

  const onValueChangedHandler = (field: string, value: string) => {
    appDispatch({
      type: stateActions.STATE_FORM_VALUE_CHANGED,
      payload: { field, value },
    });
  };

  return (
    <AnimatedDiv className={classes.wrapper} duration={0.5}>
      <div className={classes.col}>
        <OrderSection title="Contact Details">
          <div className={classes.row}>
            <label>Name</label>
            <TextBox
              className={classes.editor}
              placeholder="Auctioneer Name"
              value={appState.formData.auctioneerName}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_AUCTIONEER_NAME,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Name" />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label>Email</label>
            <TextBox
              className={classes.editor}
              placeholder="Email"
              value={appState.formData.auctioneerEmail}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_AUCTIONEER_EMAIL,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter an Email Address" />
                <EmailRule message="Please enter a valid Email Address" />
              </Validator>
            </TextBox>
          </div>

          <div className={classes.row}>
            <label>Confirm Email</label>
            <TextBox className={classes.editor} placeholder="Confirm Email">
              <Validator>
                <RequiredRule message="Please confirm Email Address" />
                <EmailRule message="Please enter a valid Email Address" />
                <CompareRule
                  message="Email addresses do not match"
                  comparisonTarget={() => appState.formData.auctioneerEmail}
                />
              </Validator>
            </TextBox>
          </div>

          <div className={classes.row}>
            <label>Address</label>
            <div className={classes.editor}>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 1"
                value={appState.formData.auctioneerAddr1}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_AUCTIONEER_ADDR1,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter an Address Line 1" />
                </Validator>
              </TextBox>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 2 (Optional)"
                value={appState.formData.auctioneerAddr2}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_AUCTIONEER_ADDR2,
                    e.value
                  )
                }
              />
              <TextBox
                className={classes.spacer}
                placeholder="Town"
                value={appState.formData.auctioneerTown}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_AUCTIONEER_TOWN,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Town" />
                </Validator>
              </TextBox>
              <SelectBox
                className={classes.spacer}
                dataSource={appState.appData.counties}
                displayExpr="name"
                placeholder="County"
                searchEnabled
                showDataBeforeSearch
                value={appState.formData.auctioneerCounty}
                valueExpr="name"
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_AUCTIONEER_COUNTY,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select a County" />
                </Validator>
              </SelectBox>
              <TextBox
                placeholder="Eircode (Optional)"
                value={appState.formData.auctioneerEircode}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_AUCTIONEER_EIRCODE,
                    e.value
                  )
                }
              />
            </div>
          </div>

          <div className={classes.row}>
            <label>Contact Number</label>
            <TextBox
              className={classes.editor}
              placeholder="Contact Number"
              value={appState.formData.auctioneerContactNumber}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_AUCTIONEER_CONTACT_NUMBER,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Contact Number" />
              </Validator>
            </TextBox>
          </div>
        </OrderSection>
        <OrderSection
          title="Client"
          visible={
            (appState.formData.payer === 'Client' && price > 0) ||
            appState.formData.sendMapTo === 'Client'
          }
        >
          <div className={classes.row}>
            <label>Name</label>
            <TextBox
              className={classes.editor}
              placeholder="Name"
              value={appState.formData.clientName}
              onValueChanged={(e: any) =>
                onValueChangedHandler(formConstants.FORM_CLIENT_NAME, e.value)
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Name" />
              </Validator>
            </TextBox>
          </div>
          {appState.formData.payer === 'Client' && (
            <>
              <div className={classes.row}>
                <label>Email</label>
                <TextBox
                  className={classes.editor}
                  placeholder="Email"
                  value={appState.formData.clientEmail}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_EMAIL,
                      e.value
                    )
                  }
                >
                  <Validator>
                    <RequiredRule message="Please enter an Email Address" />
                    <EmailRule message="Please enter a valid Email Address" />
                  </Validator>
                </TextBox>
              </div>
              <div className={classes.row}>
                <label>Confirm Email</label>
                <TextBox className={classes.editor} placeholder="Confirm Email">
                  <Validator>
                    <RequiredRule message="Please confirm Email Address" />
                    <EmailRule message="Please enter a valid Email Address" />
                    <CompareRule
                      message="Email addresses do not match"
                      comparisonTarget={() => appState.formData.clientEmail}
                    />
                  </Validator>
                </TextBox>
              </div>
            </>
          )}
          {appState.formData.withSiteSurvey && (
            <div className={classes.row}>
              <label htmlFor="">Contact Number *</label>
              <TextBox
                className={classes.editor}
                placeholder="Contact Number"
                value={appState.formData.clientContactNumber}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_CLIENT_CONTACT_NUMBER,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Contact Number" />
                </Validator>
              </TextBox>
            </div>
          )}
          {appState.formData.sendMapTo === 'Client' && (
            <div className={classes.row}>
              <label>Address</label>
              <div className={classes.editor}>
                <TextBox
                  className={classes.spacer}
                  placeholder="Address Line 1"
                  value={appState.formData.clientAddr1}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_ADDR1,
                      e.value
                    )
                  }
                >
                  <Validator>
                    <RequiredRule message="Please enter an Address Line 1" />
                  </Validator>
                </TextBox>
                <TextBox
                  className={classes.spacer}
                  placeholder="Address Line 2 (Optional)"
                  value={appState.formData.clientAddr2}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_ADDR2,
                      e.value
                    )
                  }
                />
                <TextBox
                  className={classes.spacer}
                  placeholder="Town"
                  value={appState.formData.clientTown}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_TOWN,
                      e.value
                    )
                  }
                >
                  <Validator>
                    <RequiredRule message="Please enter a Town" />
                  </Validator>
                </TextBox>
                <SelectBox
                  className={classes.spacer}
                  dataSource={appState.appData.counties}
                  displayExpr="name"
                  placeholder="County"
                  searchEnabled
                  showDataBeforeSearch
                  valueExpr="name"
                  value={appState.formData.clientCounty}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_COUNTY,
                      e.value
                    )
                  }
                >
                  <Validator>
                    <RequiredRule message="Please select a County" />
                  </Validator>
                </SelectBox>
                <TextBox
                  placeholder="Eircode (Optional)"
                  value={appState.formData.clientEircode}
                  onValueChanged={(e: any) =>
                    onValueChangedHandler(
                      formConstants.FORM_CLIENT_EIRCODE,
                      e.value
                    )
                  }
                />
              </div>
            </div>
          )}
          {appState.formData.withSiteSurvey && (
            <div className={classes.row}>
              <label style={{ fontSize: '1.25rem', fontWeight: 500 }}>
                * Required to arrange site access, necessary where site survey
                option chosen.
              </label>
            </div>
          )}
        </OrderSection>
        <OrderSection
          title="Solicitor"
          visible={appState.formData.sendMapTo === 'Solicitor'}
        >
          <div className={classes.row}>
            <label>Name</label>
            <TextBox
              className={classes.editor}
              placeholder="Solicitor Name"
              value={appState.formData.solicitorName}
              onValueChanged={(e: any) =>
                onValueChangedHandler(
                  formConstants.FORM_SOLICITOR_NAME,
                  e.value
                )
              }
            >
              <Validator>
                <RequiredRule message="Please enter a Name" />
              </Validator>
            </TextBox>
          </div>
          <div className={classes.row}>
            <label>Address</label>
            <div className={classes.editor}>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 1"
                value={appState.formData.solicitorAddr1}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_ADDR1,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter an Address Line 1" />
                </Validator>
              </TextBox>
              <TextBox
                className={classes.spacer}
                placeholder="Address Line 2 (Optional)"
                value={appState.formData.solicitorAddr2}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_ADDR2,
                    e.value
                  )
                }
              />
              <TextBox
                className={classes.spacer}
                placeholder="Town"
                value={appState.formData.solicitorTown}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_TOWN,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please enter a Town" />
                </Validator>
              </TextBox>
              <SelectBox
                className={classes.spacer}
                dataSource={appState.appData.counties}
                displayExpr="name"
                placeholder="County"
                searchEnabled
                showDataBeforeSearch
                value={appState.formData.solicitorCounty}
                valueExpr="name"
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_COUNTY,
                    e.value
                  )
                }
              >
                <Validator>
                  <RequiredRule message="Please select a County" />
                </Validator>
              </SelectBox>
              <TextBox
                placeholder="Eircode (Optional)"
                value={appState.formData.solicitorEircode}
                onValueChanged={(e: any) =>
                  onValueChangedHandler(
                    formConstants.FORM_SOLICITOR_EIRCODE,
                    e.value
                  )
                }
              />
            </div>
          </div>
        </OrderSection>
      </div>
    </AnimatedDiv>
  );
};

export default AuctioneerContactInfo;
